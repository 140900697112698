import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';;

class CheckTeacherModel extends HTTP {
    uploadCheckTeacher(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.UPLOAD_CHECK_TEACHER,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    uploadMoralStudent(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.UPLOAD_MORAL_STUDENT,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    };

    uploadCheckClass(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.UPLOAD_CHECK_CLASS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
}


export { CheckTeacherModel };
